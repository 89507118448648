<template>
  <div class="wrap">
	<my-header></my-header>
	<div class="section">
		<div class="banner">
			<template v-if="$i18n.locale=='EN'">
				<p>Join Us</p>
				<div>Let us do the right thing together when it is hard!</div>
			</template>
			<template v-else>
				<p>加入我们</p>
				<div>做正确且难的事</div>
			</template>
		</div>
		<div class="content" :class="{active:joinType==2}">
			<p class="detail_title">{{joinType==1?"社会招聘岗位":"校园招聘岗位"}}</p>
			<div>
				<ul class="post_box" v-show="joinType==1">
					<li v-for="(item,index) in postData1" :key="index" @click="handlePost(item)">
						<div class="post_name">
							<span>{{item.name}}</span>
							<b>查看详情</b>
						</div>
						<div class="post_keyword">
							{{item.keyword[0]}} | {{item.keyword[1]}} | {{item.keyword[2]}}
						</div>
					</li>
				</ul>
				<ul class="post_box" v-show="joinType==2">
					<li v-for="(item,index) in postData2" :key="index" @click="handlePost(item)">
						<div class="post_name">
							<span>{{item.name}}</span>
							<b>查看详情</b>
						</div>
						<div class="post_keyword">
							{{item.keyword[0]}} | {{item.keyword[1]}} | {{item.keyword[2]}}
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<my-footer></my-footer>
  </div>
</template>

<script>
// @ is an alias to /src   
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
  name: 'PostList',
  components: {
    MyHeader,MyFooter
  },
  data(){
	  return{
		  formData:{
			  username:'',
			  phone:'',
			  jobApplication:''
		  },
		  postData1:[
			  {name:'高级JAVA/架构师',keyword:['微服务架构','分布式技术','数据仓库']},
			  {name:'大数据工程师',keyword:['Spark','Java Hbase','Flink']},
			  {name:'机器学习算法科学家',keyword:['pytorch','深度学习框架','NLP']},
			  {name:'前端开发工程师',keyword:['WEB','移动端','JavaScript']},
			  {name:'定价精算师',keyword:['战略研究','保险评估','保险产品设计']},
			  {name:'总经理助理',keyword:['政策研究','项目跟进','内控管理']},
			  {name:'财务经理',keyword:['财务分析','全盘账务','融资尽职']},
			  {name:'商务总监',keyword:['商务谈判','商务拓展','客户关系维护']},
			  {name:'战略企划',keyword:['行业研究','竞品分析','撰写方案']}
		  ],
		  postData2:[
			  {name:'瓴先人才计划',keyword:['人工智能','机器学习','培养及储备人才']},
			  {name:'机器学习算法',keyword:['PyTorch','Tensorflow','研究深度模型']},
			  // {name:'商务助理实习生',keyword:['撰写合作资料','商务对接','客户关系维护']},
			  {name:'管培生',keyword:['轮岗学习','管理储备','专属培养方案']},
			  {name:'人力行政实习生',keyword:['招聘辅助','组织活动','文档管理']},
			  {name:'活动运营',keyword:['活动方案策划','行业竞品调研','用户需求梳理']}
		  ],
		  checkedPost:{
			  name:'',
			  duty:[
			  	'',
			  	'',
			  	''
			  ],
			  request:[
			  	'',
			  	'',
			  	''
			  ]
		  },
		  joinType:1,
		  drawerVisible:false
	  }
  },
  methods:{
	  handlePost(item){
		  this.$router.push({
			   path:'/PostDetail',
			   query:{postName:item.name}
		  });
	  }
  },
  mounted() {
	  this.joinType=this.$route.query.joinType;
  }
}
</script>
<style scoped>
	@import "../assets/css/public.css";
	.banner{
		width: 100%;
		height:4.4rem;
		padding:1.38rem 0.77rem 0;
		font-size: 0.24rem;
		font-weight: 400;
		background: url(../../public/images/join/banner.jpg) no-repeat;
		background-size: 100% 100%;
		line-height: 1.5;
		color: #fff;
	}
	.banner p{
		font-size: 0.46rem;
		line-height:1.39 ;
		margin-bottom: 0.2rem;
	}
	.content{
		padding:0.5rem 0 1.6rem;
		height: 21.66rem;
		width:100%;
		background: url(../../public/images/join/bg1.png) no-repeat;
		background-size: 100% 100%;
	}
	.content.active{
		height: 13.68rem;
		background: url(../../public/images/join/bg2.png) no-repeat;
		background-size: 100% 100%;
	}
	.detail_title{
		font-size: 0.46rem;
		font-weight: 600;
		text-align: center;
	}
	.post_box{
		width:6.7rem;
		margin:1rem auto 0;
	}
	.post_box li{
		background: url(../../public/images/join/post_bg.png) no-repeat;
		background-size: 100% 100%;
		width:100%;
		height:1.73rem;
		padding:0.4rem 0.6rem;
		margin-bottom: 0.3rem;
	}
	.post_name {
		width:100%;
		display:flex;
		justify-content: space-between;
		align-items: center;
	}
	.post_name b{
		color: #05A3E4;
		font-size: 0.24rem;
	}
	.post_keyword{
		color: #999999;
		font-size: 0.24rem;
		margin-top: 0.2rem;
		vertical-align: center;
	}
</style>